/* jQuery Countdown styles 2.0.0. */

#launch_date {
	margin: 50px auto 50px auto;
}

.countdown-rtl {
	direction: rtl;
}

.countdown-holding span {
	color: $color;
}

.countdown-row {
	clear: both;
	width: 100%;
	padding: 0px 2px;
	text-align: center;
}

.countdown {
	li {
		display: inline-block;
		font-size: 1.83rem;
		font-weight: bold;
		width: 88px;
		height: 88px;
		border-radius: 3px;
		line-height: 22px;
		padding: 18px 0;
		text-align: center;
		margin: 0 auto;
		position: relative;
		overflow: hidden;
		background: $white-0;
		z-index: -1;
		border: 5px solid $white;
		box-shadow: 0 2px 5px 0 $black-1, 0 2px 10px 0 $black-1;
		border-radius: 50%;
	}

	.time {
		font-size: 13px;
		color: $black;
		padding-top: 2px;
		font-weight: 300;
	}

	span {
		display: block;
	}

	.number {
		color: $primary;
	}
}

@media (max-width: 991px) {
	.countdown li {
		margin: 20px 10px 10px 10px;
	}

	.is-countdown {
		margin-top: 20px;
	}
}

@media (min-width: 992px) {
	.countdown li {
		margin: 0px 10px 5px 10px;
	}
}

.construction h3 {
	color: $white;
	font-size: 3.2rem;
	font-weight: 800 !important;
	margin-bottom: .4em !important;
	letter-spacing: 0;
	padding-bottom: 0;
	line-height: 1.2;
}