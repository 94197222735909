/*------------------------------------------------------------------

Project        :   Claylist - Classifieds, Directory, Multipurpose Listing HTML Template
Version        :   V.1.0
Create Date    :   19/05/22
Copyright      :   Spruko Technologies Private Limited 
Author         :   SPRUKO™
Author URL     :   https://themeforest.net/user/spruko
Support	       :   support@spruko.com
License        :   http://themeforest.net/licenses/standard

-------------------------------------------------------------------*/

/* ###### Plugins ####### */
@import "variables";
@import "plugins/accordion/accordion";
@import "plugins/boot-slider/boot-slider";
@import "plugins/date-picker/spectrum";
@import "plugins/fileuploads/dropify";
@import "plugins/gallery-image-zoom/RV-gallery";
@import "plugins/jquery-countdown/countdown";
@import "plugins/jquery-uislider/jquery-ui";
@import "plugins/owl-carousel/owl.carousel";
@import "plugins/prettycheckbox/pretty-checkbox";
@import "plugins/pscrollbar/pscrollbar";
@import "plugins/ratings-2/star-rating-svg";
@import "plugins/select2/select2";
@import "plugins/tabs/style";
@import "plugins/timeline/timeline";
@import "plugins/video/insideElementDemo";